import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { font } from "assets/variables";
import Dropdown from "./Dropdown";
import Input from "./Input";

const StyledSelect = styled(
  ({
    className,
    options,
    handleSearch,
    handleChange,
    selectedCities,
    handleSelectedDepartureCity,
  }) => {
    const [isShowing, setIsShowing] = useState(false);

    // Show or hide dropdown menu when clicking outside or clicking on the input
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (isShowing && !event.target.closest(".dropdown")) {
          setIsShowing(false);
        }
        if (
          !isShowing &&
          event.target.closest(".input") &&
          !event.target.closest(".button")
        ) {
          setIsShowing(true);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isShowing]);

    // Handle search
    const handleFilters = () => {
      setIsShowing(false);
      handleSearch();
    };

    // // Set search params when selecting a departure city
    useEffect(() => {
      handleSelectedDepartureCity(selectedCities);
    }, [selectedCities]);

    return (
      <div className={`${className} input`}>
        <div className="select">
          <Input arr={selectedCities} />
        </div>
        <Dropdown
          isShowing={isShowing}
          options={options}
          handleChange={handleChange}
          arr={selectedCities}
          handleFilters={handleFilters}
        />
      </div>
    );
  }
)`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  * {
    font-family: ${font.family.secondary};
  }

  .select {
    border: 1px solid #dbdbdb;
    display: flex;
    min-height: 45px;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 0.25rem;
    cursor: pointer;
    overflow: hidden;
    padding: 12px 14px 12px 18px;

  }

  button {
    background: #f23130;
    padding: 12px 15px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    margin-top: 0.5rem;
    cursor: pointer;
    border: none;
    width: 100%;
    font-weight: bold;
  }
`;

export default StyledSelect;
