import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import PropTypes from "prop-types";
import { color } from "assets/variables";

const PriceSlider = ({
  onChange,
  name,
  maxPrice,
  defaultValue,
  value,
  step,
}) => {
  return (
    <Slider
      name={name}
      max={maxPrice}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      step={step}
      handleStyle={{
        border: `2px solid ${color.primary.main}`,
        height: 32,
        width: 32,
        marginLeft: -14,
        marginTop: -16,
        backgroundColor: color.gray.white,
        opacity: 1,
      }}
      trackStyle={{ backgroundColor: color.primary.main, height: 2 }}
      railStyle={{ backgroundColor: "#cbd7e7", height: 2 }}
    />
  );
};

PriceSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  maxPrice: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  step: PropTypes.number,
};

export default PriceSlider;
