import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../svg/wfc-logo.svg";
import { size } from "assets/variables";
import { respondTo, rem } from "assets/mixins";

const NavBar = () => {
  return (
    <StyledNavBar>
      <div className="container">
        <a className="logo" href="https://www.weflycheap.nl/">
          <Logo />
        </a>
      </div>
    </StyledNavBar>
  );
};

const StyledNavBar = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  justify-content: center;

  .container {
    width: 100%;
    max-width: ${rem(size.containerWidth)};
    padding: ${rem(16)};

    ${respondTo(size.breakpoint.md)`
      padding: ${rem(24)};
  `}
  }

  .logo {
    cursor: pointer;
    display: inline-flex;

    svg {
      height: ${rem(32)};
    }
  }
`;

export default NavBar;
