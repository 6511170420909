import React, { useReducer, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import useHeight from "hooks/useHeight";
import { setRem, sizes } from "../../helpers/cssHelpers";
import { color, font } from "assets/variables";
import Modal from "react-modal";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Label from "./Label";
import PriceSlider from "./PriceSlider";
import Title from "./Title";
import Button from "./Button";
import CloseIcon from "components/CloseIcon";
import StyledSelect from "./StyledSelect/index";
import RadioButton from "./RadioButton";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
  z-index: 4;
  position: absolute;
  top: ${setRem(150)};
  left: ${setRem(64)};
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 3px;
  width: 340px;
  top: ${setRem(140)};
  left: ${setRem(40)};
`;

const filterReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_MAX_PRICE":
      return { ...state, maxPrice: action.payload.maxPrice };
    case "CHANGE_IS_DIRECT":
      return { ...state, isDirect: action.payload.isDirect };
    case "CHANGE_IS_ONE_WAY_TICKET":
      return { ...state, isOneWayTicket: action.payload.isOneWayTicket };
    case "CHANGE_DEPARTURE_CITIES":
      return { ...state, departureCities: action.payload.departureCities };
    case "CHANGE_SELECTED_DEPARTURE_CITY":
      return {
        ...state,
        departureCityIata: action.payload.departureCityIata,
      };
    case "CHANGE_DATES":
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    default:
      return state;
  }
};

const hideNavbar = () => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  if (
    url.searchParams.get("hide_navbar") &&
    url.searchParams.get("hide_navbar") === "true"
  ) {
    return true;
  }
  return false;
};

const Filter = React.memo(
  (props) => {
    const [state, dispatch] = useReducer(filterReducer, props.initialState);
    const [smallDevice, setSmallDevice] = useState(
      window.matchMedia(`(max-width: ${sizes.desktop}px)`).matches
    );
    const [filterVisible, setFilterVisible] = useState(false);
    const [displayDateError, setDisplayDateError] = useState(false);
    const innerHeight = useHeight();
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);

    useEffect(() => {
      if (filterVisible) {
        window.document.body.position = "fixed";
      } else {
        window.document.body.position = "static";
      }
    }, [filterVisible]);

    useEffect(() => {
      window.addEventListener("resize", () => {
        if (window.matchMedia(`(max-width: ${sizes.desktop}px)`).matches) {
          setSmallDevice(true);
        } else {
          setSmallDevice(false);
        }
      });
    }, []);

    useEffect(() => {
      const asyncFunc = async () => {
        try {
          const res = await axios.get(
            `${baseUrl}/flights/airports/origins`
          );
          handleDepartureCitiesChanged(res.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      asyncFunc();
    }, []);

    const handlePriceChange = (price) => {
      dispatch({ type: "CHANGE_MAX_PRICE", payload: { maxPrice: price } });
    };

    const handlePriceChangeInput = (event) => {
      dispatch({
        type: "CHANGE_MAX_PRICE",
        payload: { maxPrice: Number(event.target.value) },
      });
    };

    const handleIsDirectChange = (event) => {
      dispatch({
        type: "CHANGE_IS_DIRECT",
        payload: { isDirect: event.target.checked },
      });
    };

    const handleIsOneWayTicketChange = (event) => {
      dispatch({
        type: "CHANGE_IS_ONE_WAY_TICKET",
        payload: { isOneWayTicket: event.target.value === "oneWay" },
      });
    };

    const handleDepartureCitiesChanged = (departureCities) => {
      const newDepartureCities = [...state.departureCities, ...departureCities];
      dispatch({
        type: "CHANGE_DEPARTURE_CITIES",
        payload: { departureCities: newDepartureCities },
      });
    };

    const handleSelectedDepartureCity = (event) => {
      let selectedCities = event.map((city) => city.iata);
      return dispatch({
        type: "CHANGE_SELECTED_DEPARTURE_CITY",
        payload: { departureCityIata: selectedCities },
      });
    };

    const handleDatesChange = ({ startDate, endDate }) => {
      if (startDate && endDate) {
        setDisplayDateError(false);
      }
      dispatch({
        type: "CHANGE_DATES",
        payload: { startDate, endDate },
      });
    };

    const handleSearch = () => {
      if (!state.startDate || !state.endDate) {
        setDisplayDateError(true);
        return false;
      }

      if (state.startDate || state.endDate) {
        if (displayDateError) {
          setDisplayDateError(false);
        }
        const queryParams = {
          ...state,
          startDate: state.startDate.format("YYYY-MM-DD"),
          endDate: state.endDate.format("YYYY-MM-DD"),
        };
        props.onChangeQueryParams(queryParams);
        setFilterVisible(false);
      }
    };

    const handleKeyPressInput = (event) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    };

    const filteredByCity = (cities) => {
      const departureCities = [
        { country: "Nederland", iatas: ['AMS', 'RTM', 'EIN', 'MST', 'GRQ'], airports: [] },
        { country: "België", iatas: ['BRU', 'CRL'], airports: [] },
        { country: "Duitsland", iatas: ['DUS', 'NRN'], airports: [] },
      ];

      cities.forEach((city) => {
        const container = {
          value: city.name.toLowerCase(),
          label: city.name,
          iata: city.iata,
        };

        for (const departureCity of departureCities) {
            if (departureCity.iatas.includes(city.iata)) {
                departureCity.airports.push(container)
            }

            departureCity.airports.sort(function (a, b) {
              return departureCity.iatas.indexOf(a.iata) - departureCity.iatas.indexOf(b.iata)
            })
        }
      });

      return departureCities;
    };

    useEffect(() => {
      let departureCities = [...state.departureCities];
      let filteredDepartureCities = departureCities.filter(
        (city) => city.name !== "Alle Steden"
      );

      setCities(filteredByCity(filteredDepartureCities));
    }, [state.departureCities]);

    const handleChange = (value) => {
      const arr = [...selectedCities];

      if (
        selectedCities.filter((item) => item.label === value.label).length > 0
      ) {
        const index = arr.findIndex((item) => item.label === value.label);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }

      setSelectedCities(arr);
    };

    const renderInputFields = () => {
      return (
        <>
          <Title>Pas filters aan</Title>
          <StyledSelect
            options={cities}
            selectedCities={selectedCities}
            handleSelectedDepartureCity={handleSelectedDepartureCity}
            handleSearch={handleSearch}
            handleChange={handleChange}
          />
          {displayDateError && (
            <DateError>Selecteer a.u.b. eerst een periode</DateError>
          )}
          <DatePicker
            endDate={state.endDate}
            startDate={state.startDate}
            orientation={smallDevice ? "vertical" : "horizontal"}
            onDatesChange={handleDatesChange}
          />
          <Label
            htmlFor="budget"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "24px",
              marginBottom: "32px",
              fontFamily: font.family.main,
              fontSize: "1rem",
            }}
          >
            Toon tickets tot
            <div>
              <span
                style={{ fontWeight: font.weight.bold, marginRight: "5px" }}
              >
                €
              </span>

              <Input
                type="number"
                min="0"
                max="2000"
                value={state.maxPrice.toString()}
                onChange={handlePriceChangeInput}
                onKeyPress={handleKeyPressInput}
              />
            </div>
          </Label>
          <PriceSlider
            maxPrice={2000}
            onChange={handlePriceChange}
            name="budget"
            defaultValue={state.maxPrice}
            value={state.maxPrice}
            step={10}
          />
          <label style={{ marginTop: "1.5rem" }}>
            <Checkbox
              checked={state.isDirect}
              onChange={handleIsDirectChange}
            />
            <span
              style={{
                marginLeft: "16px",
                letterSpacing: "1px",
                fontFamily: font.family.main,
                fontSize: "1rem",
              }}
            >
              Toon alleen directe vluchten
            </span>
          </label>
          <RadioWrapper>
            <label style={{ marginTop: "1.5rem" }}>
              <RadioButton
                name="trip-type-selector"
                value="return"
                checked={!state.isOneWayTicket}
                onChange={handleIsOneWayTicketChange}
              />
              <span
                style={{
                  marginLeft: "16px",
                  letterSpacing: "1px",
                  fontFamily: font.family.main,
                  fontSize: "1rem",
                }}
              >
                Retour
              </span>
            </label>
            <label style={{ marginTop: "1.5rem" }}>
              <RadioButton
                name="trip-type-selector"
                value="oneWay"
                checked={state.isOneWayTicket}
                onChange={handleIsOneWayTicketChange}
              />
              <span
                style={{
                  marginLeft: "16px",
                  letterSpacing: "1px",
                  fontFamily: font.family.main,
                  fontSize: "1rem",
                }}
              >
                Enkele reis
              </span>
            </label>
          </RadioWrapper>
          <Button onClick={handleSearch}>Toon Tickets</Button>
        </>
      );
    };

    const renderFilter = (smallDevice) => {
      if (smallDevice) {
        return (
          <>
            <FilterWrapper
              hideNavbar={hideNavbar()}
              onClick={() => setFilterVisible(true)}
            >
              <FilterIcon viewBox="0 0 24 24">
                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
              </FilterIcon>
            </FilterWrapper>
            <Modal
              style={{ content: { height: innerHeight } }}
              closeTimeoutMS={100}
              onFocus={true}
              isOpen={filterVisible}
              contentLabel="onRequestClose Example"
              onRequestClose={() => setFilterVisible(false)}
              className="Modal"
              overlayClassName="Overlay"
            >
              <Wrapper>
                <CloseIcon
                  onClick={() => setFilterVisible(false)}
                  smallDevice={smallDevice}
                />
                {renderInputFields()}
              </Wrapper>
            </Modal>
          </>
        );
      } else {
        return <Card>{renderInputFields()}</Card>;
      }
    };
    return renderFilter(smallDevice);
  },
  (prevProps, nextProps) => {
    if (prevProps.initialQueryParams !== nextProps.initialQueryParams) {
      return false;
    }
    return true;
  }
);

const DateError = styled.span`
  font-size: ${setRem(14)};
  font-family: ${font.family.main};
  margin-bottom: ${setRem(6)};
  color: red;
`;

// const Icon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const CloseIcon = styled(Icon)`
//   width: 1.4rem;
//   height: 1.4rem;
//   align-self: flex-end;
//   &:hover {
//     cursor: pointer;
//     fill: #9aacca;
//   }
// `;

const FilterIcon = styled.svg`
  flex: none;
  transition: fill 0.25s;
  width: 1.4rem;
  height: 1.4rem;
  fill: #ffffff;
`;

const FilterWrapper = styled.div`
  z-index: 4;
  border-radius: 50%;
  padding: 16px;
  position: absolute;
  // top: ${(props) => (props.hideNavbar ? setRem(10) : setRem(140))};
  bottom: ${setRem(30)};
  left: ${setRem(30)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.primary.main};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
`;

const Input = styled.input`
    font-size: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    padding: 8px 10px 8px 14px;
    width: 125px; 
    font-family: ${font.family.secondary};
    font-weight: ${font.weight.bold};
    color: #2e3742;
    
    :focus{
      outline-width: 0;
    }
  }`;

const RadioWrapper = styled.div`
  display: flex;
  gap: 50px;
  user-select: none;
`;

// prettier-ignore

export default Filter;
