import axios from "axios";
import React, { useState, useEffect } from "react";
import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl";
import { MarkerPin, NavBar } from "./components";
import QuotesPopup from "./blocks/QuotesPopup";
import Filter from "./blocks/Filter";
import GlobalStyle from "./theme/globalStyle";
import Cluster from "@urbica/react-map-gl-cluster";
import Modal from "react-modal";
import moment from "moment";
import styled from "styled-components";
import Spinner from "components/Spinner";
import useHeight from "hooks/useHeight";
import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";

require("dotenv").config();
Modal.setAppElement("#root");
const baseUrl = process.env.REACT_APP_BASE_API_URL;
const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const initialQueryParams = {
  departureCityIata: "",
  isDirect: false,
  departureCities: [{ name: "Alle Steden", id: "" }],
  startDate: moment(),
  endDate: moment().add(6, "months"),
  maxPrice: 900,
};

const hideNavbar = (Component) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  if (
    url.searchParams.get("hide_navbar") &&
    url.searchParams.get("hide_navbar") === "true"
  ) {
    return null;
  }
  return <Component />;
};

const getQueryParams = (callback) => {
  let isRendered = false;
  return () => {
    if (!isRendered) {
      isRendered = true;
      const urlString = window.location.href;
      const url = new URL(urlString);
      const isDirect = url.searchParams.get("direct");
      const isOneWayTicket = url.searchParams.get("one-way");
      const departureCityIata = url.searchParams.get("vertrek_iata");
      const startDate = url.searchParams.get("start");
      const endDate = url.searchParams.get("eind");
      const maxPrice = url.searchParams.get("budget");

      //Url example:  /?direct=false&start=04-07-2019&eind=01-09-2019&budget=40&vertrek_iata=lon&bestemming_iata=lon&continent=europa
      return {
        ...initialQueryParams,
        departureCities: [{ name: "Alle Steden", iata: "" }],
        departureCityIata: departureCityIata,
        isOneWayTicket: isOneWayTicket === "true",
        isDirect: isDirect === "true",
        startDate: startDate
          ? moment(startDate, "DD-MM-YYYY")
          : initialQueryParams.startDate,
        endDate: endDate
          ? moment(endDate, "DD-MM-YYYY")
          : initialQueryParams.endDate,
        maxPrice: maxPrice ? parseInt(maxPrice) : initialQueryParams.maxPrice,
      };
    }
  };
};

const qP = getQueryParams();

const App = () => {
  window.location = 'https://www.weflycheap.nl/vliegkaart';
  return;
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [queryParams, setQueryParams] = useState(qP());
  const [viewport, setViewport] = useState(getInitialViewPort());
  const innerHeight = useHeight();

  useEffect(() => {
    setCities([]);
    setIsLoading(true);
    axios
      .get(`${baseUrl}${buildQueryParams(queryParams)}`)
      .then((res) => {
        setIsLoading(false);
        setCities(res.data.data);
      })
      .catch((e) => {
        setError("Er is iets mis gegaan. Probeer het later nog eens.");
      });
  }, [queryParams]);

  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const departureIata = url.searchParams.get("vertrek_iata");
    const destinationIata = url.searchParams.get("bestemming_iata");

    if (departureIata && destinationIata) {
      axios
        .get(
          `${baseUrl}/flights/airports?iata=${departureIata},${destinationIata}`
        )
        .then((res) => {
          const iatas = res.data.data;

          if (iatas.length === 2) {
            setPopupInfo({
              destinationCity: iatas.find(
                (city) =>
                  city.iata.toLowerCase() === destinationIata.toLowerCase()
              ),
              departureCity: iatas.find(
                (city) =>
                  city.iata.toLowerCase() === departureIata.toLowerCase()
              ),
            });
          }
        });
    }
  }, []);

  const ClusterMarker = ({ longitude, latitude, pointCount }) => {
    const zoomIn = () => {
      setViewport({
        viewportChangeMethod: "flyTo",
        ...viewport,
        latitude,
        longitude,
        zoom: viewport.zoom + 3,
      });
    };

    return (
      <Marker longitude={longitude} latitude={latitude}>
        <MarkerPin onClick={zoomIn}>+{pointCount}</MarkerPin>
      </Marker>
    );
  };

  const buildQueryParams = ({
    departureCityIata,
    isDirect,
    isOneWayTicket,
    startDate,
    endDate,
    maxPrice,
  }) => {
    const origin_iata = Array.isArray(departureCityIata) ? departureCityIata.join(',') : '';

    const params = new URLSearchParams({
      origin_iata,
      is_direct: isDirect ? 1 : 0,
      is_one_way: isOneWayTicket ? 1 : 0,
      min_date: moment(startDate).format("YYYY-MM-DD"),
      max_date: moment(endDate).format("YYYY-MM-DD"),
      max_price: maxPrice,
    });

    return `/flights?${params.toString()}`;
  };

  const handleCloseModal = () => {
    setPopupInfo(null);
  };

  const onChangeQueryParams = (newQueryParams) => {
    setQueryParams(newQueryParams);
  };

  const renderCityMarkers = (cities) => {
    return (
      <Cluster radius={50} extent={512} nodeSize={65} component={ClusterMarker}>
        {cities.map((city, index) => (
          <Marker
            offset={[28, -28]}
            key={index}
            longitude={city.destinationCity.longitude}
            latitude={city.destinationCity.latitude}
          >
            <MarkerPin onClick={() => setPopupInfo(city)}>
              €{city.price}
            </MarkerPin>
          </Marker>
        ))}
      </Cluster>
    );
  };

  const renderQuotesPopup = () => {
    return (
      <Modal
        closeTimeoutMS={800}
        autoFocus={false}
        isOpen={Boolean(popupInfo)}
        onRequestClose={() => handleCloseModal()}
        className="Modal"
        overlayClassName="Overlay"
      >
        <QuotesPopup
          quote={popupInfo}
          queryParams={queryParams}
          isOpen={true}
          closeModal={handleCloseModal}
          contentLabel="Example Modal"
        />
      </Modal>
    );
  };

  return (
    <>
      <GlobalStyle />
      <Wrapper innerHeight={innerHeight}>
        {hideNavbar(NavBar)}
        <MapGL
          style={{ width: "100%", height: "100%" }}
          lattitude={52.379189}
          longitude={4.899431}
          mapStyle="mapbox://styles/mapbox/light-v9"
          accessToken={accessToken}
          onViewportChange={(viewport) => setViewport(viewport)}
          {...viewport}
        >
          <NavigationControl showCompass showZoom position="top-right" />
          {cities.length > 0 && renderCityMarkers(cities)}
          {popupInfo && renderQuotesPopup()}
        </MapGL>
        <Filter
          onChangeQueryParams={onChangeQueryParams}
          initialState={queryParams}
        />
        {isLoading && <Spinner className="spinner" size={64} />}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  height: ${(props) =>
    props.innerHeight ? `${props.innerHeight}px` : "100vh"};
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

function getInitialViewPort() {
  const urlString = window.location.href;
  const url = new URL(urlString);
  // const filtering = url.searchParams.get('filtering');
  const continent = url.searchParams.get("continent");

  return {
    latitude: continents[continent]
      ? continents[continent].latitude
      : continents.europa.latitude,
    longitude: continents[continent]
      ? continents[continent].longitude
      : continents.europa.longitude,
    zoom: 3.3,
    bearing: 0,
    pitch: 0,
    width: "100vw",
    height: "100vh",
  };
}

const continents = {
  afrika: {
    latitude: -4.0383329,
    longitude: 21.7586632,
  },
  oostAzie: {
    latitude: 13.839353,
    longitude: 100.762726,
  },
  middenAzie: {
    latitude: 35.890321,
    longitude: 61.789562,
  },
  europa: {
    latitude: 52.379189,
    longitude: 4.899431,
  },
  noordAmerika: {
    latitude: 36.290658,
    longitude: -99.149865,
  },
  zuidAmerika: {
    latitude: -15.463674,
    longitude: -58.529443,
  },
  australie: {
    latitude: -24.106247,
    longitude: 134.13646,
  },
  caraiben: {
    latitude: 17.754999,
    longitude: -77.455001,
  },
};

export default App;
