import styled from "styled-components";
import { setRem, setEm } from "../../helpers/cssHelpers";
import { color, font } from "assets/variables";

export const TableSection = styled.div`
  width: 100%;
  background-color: #818181;
`;

export const Table = styled.div`
  display: grid;
  width: 100%;
  background-color: #fff;
  grid-template-columns:
    minmax(${setRem(80)}, ${setRem(140)})
    minmax(${setRem(120)}, ${setRem(230)})
    minmax(${setRem(100)}, ${setRem(170)})
    minmax(${setRem(200)}, 1fr)
    ${setRem(70)};

  .small {
    font-size: ${setRem(14)};
    display: flex;
    align-items: center;
  }
`;

export const Col = styled.span`
  padding: ${setRem(24)} ${setRem(8)};
  color: #434343;
  font-size: ${setRem(18)};
  letter-spacing: ${setRem(1)};
  font-family: ${font.family.main};
  border-top: 1px solid #e1e1e1;
`;

export const Card = styled.li`
  display: grid;
  border-radius: 5px;
  grid-template-areas: "price" "content" "button";
  grid-gap: 1rem;
  grid-row: 1;
  //margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: ${setRem(14)};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
`;

export const Content = styled.div`
  grid-area: content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PriceTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextSmall = styled.span`
  color: #b8b8b8;
  font-size: ${setRem(16)};
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
`;

export const PriceInfo = styled(TextSmall)`
  color: #b8b8b8;
  margin-bottom: ${setRem(3)};
  text-align: center;
  font-family: ${font.family.main};
  font-weight: ${font.weight.semibold};
`;

export const Found = styled(TextSmall)`
  color: #b8b8b8;
  margin-top: ${setRem(14)};
  text-align: center;
`;

export const Line = styled.span`
  margin: 20px 0;
  border-bottom: 1px solid #c9c9c9;
  width: 100px;
`;

export const PriceTextPrice = styled.span`
  color: ${color.primary.main};
  font-size: ${setRem(32)};
  font-family: ${font.family.secondary};
  font-weight: ${font.weight.bold};
  letter-spacing: 1.32px;
  text-align: center;
`;

export const Text = styled.p`
  color: #3a3a3a;
  font-size: ${setRem(18)};
  font-family: ${font.family.main};
  margin-bottom: ${setRem(3)};
  text-align: center;
`;

export const Button = styled.button`
  grid-area: button;
  background-color: ${color.primary.main};
  border-radius: 3px;
  color: #fff;
  padding: ${setRem(8)} ${setRem(6)};
  border: none;
  margin-top: ${setRem(12)};
  font-size: ${setRem(20)};
  font-family: ${font.family.secondary};
  font-weight: ${font.weight.bold};
  text-transform: uppercase;
  letter-spacing: 1.32px;
  text-align: center;
  text-decoration: none;
  padding: ${setEm(12)}};

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Iata = styled.h3`
  text-align: center;
  font-size: ${setRem(24)};
  font-family: ${font.family.secondary};
  font-weight: ${font.weight.bold};
  letter-spacing: ${setRem(4)};
`;
