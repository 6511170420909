import React from "react";
import styled from "styled-components";
import { color, font } from "assets/variables";
import { rem } from "assets/mixins";

const Dropdown = styled(
  ({ className, isShowing, options, handleChange, arr, handleFilters }) => {
    return (
      <div className={`${className} dropdown`}>
        {isShowing ? (
          <div className="select-container">
            {options.map((option, index) => (
              <React.Fragment key={index}>
                <h4 className="country">{option.country}</h4>
                {option.airports.map((airport) => (
                  <div key={airport.label} className="select-option">
                    <label className="container">
                      <input
                        type="checkbox"
                        onChange={() => handleChange(airport)}
                        checked={
                          arr.filter((item) => item.label === airport.label)
                            .length > 0
                        }
                      />
                      <div className="label">{airport.label}</div>
                      <span className="checkmark" />
                    </label>
                  </div>
                ))}
              </React.Fragment>
            ))}
            <button className="button" onClick={handleFilters}>
              toon resultaten
            </button>
          </div>
        ) : null}
      </div>
    );
  }
)`
  .select-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;

    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 5;
    padding: 25px;
    border: 1px solid #dbdbdb;
    border-radius: 8px;

    .select-option {
      margin-bottom: 0.5rem;
    }

    .country {
      color: ${color.gray.light};
      font-size: ${rem(16)};
      margin-bottom: 12px;
      opacity: 0.9;
    }
  }

  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .label {
      margin-left: 10px;
      color: ${color.gray.dark};
      font-family: ${font.family.main};
    }

    input:checked + .label {
      font-weight: bold;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: solid 1px #fddede;
      background-color: #fff;
    }
  }

  .container input:checked ~ .checkmark {
    background-color: red;
    border: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default Dropdown;
// handleChange(option)
