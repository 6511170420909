import styled from "styled-components";
import { color, font, size } from "assets/variables";
import { rem, elevate } from "assets/mixins";

const Button = styled.button`
  margin-top: ${rem(16)};
  text-align: center;
  text-transform: lowercase;
  box-sizing: border-box;
  opacity: 1;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: none;
  border: none;
  background-color: ${color.primary.main};
  color: ${color.gray.white};
  border-radius: ${size.radius.primary};
  font-family: ${font.family.secondary};
  font-weight: ${font.weight.semibold};
  font-size: ${rem(20)};
  padding: ${rem(12)} ${rem(16)};
  transition: box-shadow 300ms;
  width: 100%;

  ${elevate(6, color.primary.main)}

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    transition: box-shadow 300ms;

    ${elevate(8, color.primary.main)}
  }
`;

export default Button;
