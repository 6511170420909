import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { rem } from "assets/mixins";
import { color } from "assets/variables";

const RadioButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
`;

const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledRadioButton = styled.div`
  display: inline-block;
  width: ${rem(18)};
  height: ${rem(18)};
  outline: 5px solid
    ${(props) => (props.checked ? color.primary.main : color.gray.white)};
  outline-offset: -10px;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.checked ? color.primary.light : color.primary.light)};
  transition: all 150ms;
`;

const RadioButton = ({ checked, name, value, onChange }) => (
  <RadioButtonContainer>
    <HiddenRadioButton
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <StyledRadioButton checked={checked} />
  </RadioButtonContainer>
);

RadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioButton;
