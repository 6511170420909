import { css } from "styled-components";

export const setColor = {
  primaryColor: "#c20000",
  secondaryColor: "#019dc6",
  mainWhite: "#fff",
  mainBlack: "#1a1a1a",
  mainGrey: "#f4f4f4",
};

export const sizes = {
  large: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const setRem = (pixel = 16) => `${pixel / 16}rem`;
export const setEm = (pixel = 16) => `${pixel / 16}em`;

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
