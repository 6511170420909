import styled from "styled-components";
import React from "react";
import { ReactComponent as CloseSVG } from "../svg/close.svg";
import { setRem } from "../helpers/cssHelpers";

const CloseIcon = ({ onClick, smallDevice }) => {
  if (smallDevice) {
    return (
      <MobileClose onClick={onClick}>
        <CloseSVG />
      </MobileClose>
    );
  }

  return (
    <Close onClick={onClick}>
      <CloseSVG />
    </Close>
  );
};

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${setRem(16)};
`;

const Close = styled(Icon)`
  width: 1.4rem;
  height: 1.4rem;
  align-self: flex-end;
  &:hover {
    cursor: pointer;
    fill: #9aacca;
  }
`;
const MobileClose = styled(Icon)`
  z-index: 999;
  position: fixed;
  bottom: ${setRem(12)};
  right: ${setRem(30)};
  background: #f23130;
  padding: 16px;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);

  svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
  }
`;

export default CloseIcon;
