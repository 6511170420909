import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { font } from "assets/variables";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay.js";
import "react-dates/lib/css/_datepicker.css";

const START_DATE = "startDate";
const END_DATE = "endDate";

const defaultStyles = {
  border: "1px solid transparent",
  fontFamily: font.family.secondary,
  fontWeight: "600",

  hover: {
    background: "rgb(253, 222, 222)",
    fontFamily: font.family.secondary,
    fontWeight: "600",
    borderRadius: "4px",
  },
};

const hoveredStyles = {
  hover: {
    background: "rgb(253, 222, 222)",
    fontFamily: font.family.secondary,
    fontWeight: "600",
    borderRadius: "4px",
  },
};

const selectedStartStyles = {
  background: "#f33131",
  border: "none",
  color: "#fff",
  borderRadius: "4px 0 0 4px",
};

const selectedEndStyles = {
  background: "#f33131",
  border: "1px solid transparent",
  color: "#fff",
  borderRadius: "0 4px 4px 0",
};

const selectedSpanStyles = {
  background: "rgb(253, 222, 222)",
  border: "1px solid transparent",
  color: "rgb(243, 49, 49)",
};

const todayStyles = {
  color: "rgb(208, 2, 27)",
  fontWeight: "700",
};

const blockedOutOfRangeStyles = {
  background: "black",
};

const customDayStyles = {
  defaultStyles: defaultStyles,
  selectedStartStyles: selectedStartStyles,
  selectedEndStyles: selectedEndStyles,
  hoveredSpanStyles: hoveredStyles,
  selectedSpanStyles: selectedSpanStyles,
  selectedStyles: selectedSpanStyles,
  todayStyles: todayStyles,
  blockedOutOfRangeStyles: blockedOutOfRangeStyles,
};

const DateRangePicker = ({
  autoFocusEndDate,
  showInputs,
  initStartDate,
  initEndDate,
  handleDatesChange,
  orientation,
}) => {
  const [focusedInput, setFocusedInput] = useState(
    autoFocusEndDate ? END_DATE : START_DATE
  );
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [showDatePicker, setShowDatePicker] = useState(null);

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    handleDatesChange({ startDate, endDate });
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(!focusedInput ? START_DATE : focusedInput);
  };

  const clearDateSelection = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const setDateSelection = () => {
    setShowDatePicker(false);
  };

  const renderControls = () => {
    return (
      <Footer>
        <span onClick={() => clearDateSelection()}>Wis</span>
        <span onClick={() => setDateSelection()}>Sluiten</span>
      </Footer>
    );
  };

  const startDateString = startDate && startDate.format("DD MMM");
  const endDateString = endDate && endDate.format("DD MMM");
  const dateRangeString =
    startDate && endDate
      ? `${startDateString} T/M ${endDateString}`
      : "REISPERIODE";

  return (
    <DayPickerWrapper>
      {showInputs && (
        <Input onClick={() => setShowDatePicker(!showDatePicker)}>
          <svg
            class="DateRangePickerInput_calendarIcon_svg DateRangePickerInput_calendarIcon_svg_1"
            focusable="false"
            viewBox="0 0 1393.1 1500"
          >
            <path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z" />
          </svg>
          <div>{dateRangeString}</div>
        </Input>
      )}

      {showDatePicker && (
        <DayPickerRangeController
          onFocusChange={onFocusChange}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          keepOpenOnDateSelect={false}
          enableOutsideDays={false}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={2}
          onOutsideClick={() => setShowDatePicker(false)}
          orientation={orientation}
          navNext={
            orientation === "horizontal" && (
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.12,9.29 L12,13.17 L15.88,9.29 C16.27,8.9 16.9,8.9 17.29,9.29 C17.68,9.68 17.68,10.31 17.29,10.7 L12.7,15.29 C12.31,15.68 11.68,15.68 11.29,15.29 L6.7,10.7 C6.31,10.31 6.31,9.68 6.7,9.29 C7.09,8.91 7.73,8.9 8.12,9.29 Z" />
              </svg>
            )
          }
          navPrev={
            orientation === "horizontal" && (
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                name="next"
              >
                <path d="M8.12,9.29 L12,13.17 L15.88,9.29 C16.27,8.9 16.9,8.9 17.29,9.29 C17.68,9.68 17.68,10.31 17.29,10.7 L12.7,15.29 C12.31,15.68 11.68,15.68 11.29,15.29 L6.7,10.7 C6.31,10.31 6.31,9.68 6.7,9.29 C7.09,8.91 7.73,8.9 8.12,9.29 Z" />
              </svg>
            )
          }
          renderCalendarDay={(props) => (
            <CustomizableCalendarDay {...props} {...customDayStyles} />
          )}
          renderCalendarInfo={renderControls}
        />
      )}
    </DayPickerWrapper>
  );
};

DateRangePicker.propTypes = {
  autoFocusEndDate: PropTypes.bool,
  showInputs: PropTypes.bool,
  startDateId: PropTypes.string,
  endDateId: PropTypes.string,
};
const DayPickerWrapper = styled.div`
  position: relative;
  .DayPickerNavigation_button {
    position: absolute;
    top: 10px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
  }
  @media only screen and (max-width: 600px) {
    .DayPickerNavigation {
      bottom: 60px;

      .DayPickerNavigation_button {
        box-shadow: none;
      }
    }
  }

  .DayPickerNavigation_button[aria-label~="next"] {
    right: 0;
    @media only screen and (min-width: 992px) {
      right: 22px;
      svg {
        rotate: -90deg;
      }
    }
  }
  .DayPickerNavigation_button[aria-label~="previous"] {
    @media only screen and (min-width: 992px) {
      left: 22px;
      svg {
        rotate: 90deg;
      }
    }
  }
`;

const Input = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #2e3742;
  margin-right: 0.6rem;
  font-family: Rajdhani, Arial;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: 0.88px;
  line-height: 24px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding-left: 1.5rem;
  cursor: pointer;

  svg {
    margin-bottom: 3px;
  }

  div {
    margin-left: 2rem;

    @media (max-width: 992px) {
      text-align: center;
      width: calc(100% - 44px);
      margin-left: 0;
    }
  }

`;

const Footer = styled.section`
  dispplay: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: rgb(247, 248, 250);
  display: flex;
  flex-flow: row wrap;
  border-radius: 0px 0px 8px 8px;

  span {
    text-transform: uppercase;
    letter-spacing: 0.055rem;
    font-family: ${font.family.secondary};
    cursor: pointer;
  }
`;

export default DateRangePicker;
