import styled from "styled-components";
import React from "react";
import { font, color } from "assets/variables";

const MarkerPin = ({ onClick, children }) => {
  return (
    <Wrapper onClick={onClick}>
      <div className="marker">{children}</div>
      <div className="arrow" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top - 20}px;
  box-shadow: 0px 8px 16px -8px rgb(46, 55, 66);

  &:hover {
    cursor: pointer;
  }

  .marker {
    background: ${color.primary.main};
    padding: 4px 8px;
    font-family: ${font.family.secondary};
    font-weight: ${font.weight.semibold};
    letter-spacing: 1.32px;
    line-height: 24px;
    font-size: 24px;
    color: #fff;
    white-space: nowrap;
    z-index: 5;
  }
  .arrow {
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 16px solid #882121;
    border-right: 8px solid transparent;
    position: absolute;
    top: 30px;
    left: 0;
    margin-right: -3px;
  }
`;
export default MarkerPin;
