import { css } from "styled-components";

/**
 * Respond To Media Queries for different devices.
 * @param {number} deviceWidth - Size of the breakpoint
 * @param {string} type ('min') - Types of breakpoint (min or max width).
 */
export const respondTo = (deviceWidth, type = "min") => {
  if (!deviceWidth) return () => ``;

  return (...content) => css`
        @media only screen and (${type}-width: ${
    type === "max" ? deviceWidth - 1 : deviceWidth
  }px) {
        // @ts-ignore
            ${css(...content)}
        }
    `;
};

/**
 * Converts pixels to rem.
 * @param {number} px - pixel value
 */
export const rem = (px = 16) => `${px / 16}rem`;

/**
 * Converts hex color to rem.
 * @param {string, null} hex - hex decimal value
 */
function hexToRgb(hex) {
  if (!hex) return null;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Elevation mixin to get elevation values.
 * @param {number} level - The number of desired elevation level.
 * @param {string, null} color - The number of desired elevation level.
 */
export const elevate = (level, color = null) => {
  // check if color is set and transform color(hex) to rgb value
  let transformedColor = { r: 0, g: 0, b: 0 };
  if (color) {
    const rgb = hexToRgb(color);
    if (rgb) {
      transformedColor = rgb;
    }
  }
  const { r = 0, g = 0, b = 0 } = transformedColor;

  const keyShadowUmbraColor = `rgba(${r}, ${g}, ${b}, 0.14 )`;
  const keyShadowPenumbraColor = `rgba(${r}, ${g}, ${b}, 0.12 )`;
  const ambientShadowColor = `rgba(${r}, ${g}, ${b}, 0.2 )`;
  const keyShadowUmbraMap = {
    1: "0 0 2px 0",
    2: "0 0 4px 0",
    3: "0 1px 6px 0",
    4: "0 2px 4px 0",
    5: "0 4px 6px 0",
    6: "0 6px 10px 0",
    7: "0 6px 10px 0",
    8: "0 8px 10px 1px",
    9: "0 9px 12px 2px",
    10: "0 10px 13px 2px",
    11: "0 11px 14px 2px",
    12: "0 12px 16px 2px",
    13: "0 13px 18px 2px",
    14: "0 14px 21px 2px",
    15: "0 15px 22px 2px",
    16: "0 16px 23px 2px",
    17: "0 17px 24px 2px",
    18: "0 18px 25px 3px",
    19: "0 19px 26px 3px",
    20: "0 20px 28px 3px",
    21: "0 21px 30px 3px",
    22: "0 22px 32px 3px",
    23: "0 23px 36px 3px",
    24: "0 24px 38px 3px",
  };
  const keyShadowPenumbraMap = {
    1: "0 2px 2px 0",
    2: "0 3px 4px 0",
    3: "0 3px 4px 0",
    4: "0 4px 5px 0",
    5: "0 4px 5px 0",
    6: "0 1px 18px 0",
    7: "0 2px 18px 1px",
    8: "0 3px 14px 2px",
    9: "0 3px 18px 3px",
    10: "0 4px 16px 3px",
    11: "0 4px 18px 3px",
    12: "0 5px 22px 4px",
    13: "0 5px 26px 4px",
    14: "0 5px 29px 4px",
    15: "0 6px 34px 5px",
    16: "0 6px 30px 5px",
    17: "0 6px 36px 5px",
    18: "0 7px 33px 6px",
    19: "0 7px 37px 6px",
    20: "0 8px 34px 6px",
    21: "0 8px 39px 7px",
    22: "0 9px 38px 7px",
    23: "0 9px 40px 7px",
    24: "0 9px 46px 8px",
  };
  const ambientShadowMap = {
    1: "0 1px 3px 0",
    2: "0 1px 5px 0",
    3: "0 1px 7px 0",
    4: "0 1px 8px 0",
    5: "0 1px 10px 0",
    6: "0 3px 5px 0",
    7: "0 3px 8px 0",
    8: "0 4px 15px 0",
    9: "0 5px 11px 0",
    10: "0 5px 14px 0",
    11: "0 6px 10px 0",
    12: "0 6px 13px 0",
    13: "0 7px 10px 0",
    14: "0 7px 12px 0",
    15: "0 7px 14px 0",
    16: "0 8px 13px 0",
    17: "0 8px 15px 0",
    18: "0 9px 11px 0",
    19: "0 9px 13px 0",
    20: "0 9px 15px 0",
    21: "0 10px 12px 0",
    22: "0 10px 15px 0",
    23: "0 11px 13px 0",
    24: "0 11px 15px 0",
  };

  if (!level || !ambientShadowMap.hasOwnProperty(level)) return "";

  return `
        box-shadow: ${keyShadowUmbraMap[level]} ${keyShadowUmbraColor},
                    ${keyShadowPenumbraMap[level]} ${keyShadowPenumbraColor},
                    ${ambientShadowMap[level]} ${ambientShadowColor};
    `;
};
