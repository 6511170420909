export const color = {
  gray: {
    white: "#FFFFFF",
    lightGray: "#f7f8fa",
    darkerGray: "#E1E2E4",
    border: "#E5E9ED",
    light: "#93a3b8",
    dark: "#2E3742",
    blueGray: "#5F7585",
    darkGreen: "#216369",
    selectActive: "#2e3742",
    black: "#000000",
  },
  primary: {
    main: "#F33131",
    light: "#FDDEDE",
  },
  secondary: {
    main: "#8EE6EF",
    light: "#DEF8FA",
    secondary_alpha: "rgba(142, 230, 239, 0.29)",
  },
  alpha: {
    high: 0.22,
    medium: 0.58,
    low: 0.88,
    overlay: 0.94,
  },
};

export const size = {
  radius: {
    primary: "8px",
    secondary: "4px",
  },
  gutterWidth: 24,
  containerWidth: 1440,
  breakpoint: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export const font = {
  family: {
    fallback: "Helvetica Neue, Helvetica, Arial, sans-serif",
    get main() {
      return `Open Sans, ${font.family.fallback}`;
    },
    get secondary() {
      return `Rajdhani, ${font.family.fallback}`;
    },
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};
