import React from "react";
import PropTypes from "prop-types";
import "moment/locale/nl";
import "react-dates/lib/css/_datepicker.css";
import "./DatePicker.css";
import "react-dates/initialize";
import DateRangePicker from "./DateRangePicker";

const DatePicker = ({ orientation, onDatesChange, startDate, endDate }) => {

  return (
    <DateRangePicker
      startDateId="search_start_date"
      endDateId="search_end_date"
      initStartDate={startDate}
      initEndDate={endDate}
      autoFocusEndDate={false}
      showInputs={true}
      handleDatesChange={onDatesChange}
      orientation={orientation}
    />
  );
};

DatePicker.propTypes = {
  orientation: PropTypes.string.isRequired,
  onDatesChange: PropTypes.func.isRequired,
};

export default DatePicker;
