import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { rem } from "assets/mixins";
import { color } from "assets/variables";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${color.gray.white};
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${rem(18)};
  height: ${rem(18)};
  background: ${(props) =>
    props.checked ? color.primary.main : color.gray.white};
  border-radius: 3px;
  border: 1px solid
    ${(props) => (props.checked ? color.primary.main : color.primary.light)};
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
};

export default Checkbox;
