import { Marker } from "@urbica/react-map-gl";
import React from "react";
import { setRem } from "../helpers/cssHelpers";
import styled from "styled-components";

const ClusterMarker = ({ longitude, latitude, pointCount }) => {
  const zoomIn = () => {};

  return (
    <Marker longitude={longitude} latitude={latitude} onClick={zoomIn}>
      <ClusterMarkerStyle>{pointCount} prijzen..</ClusterMarkerStyle>
    </Marker>
  );
};

const ClusterMarkerStyle = styled.div`
  color: #ffffff;
  border-radius: 4px;
  background-color: #59cd90;
  padding: ${setRem(8)} ${setRem(10)};
`;

export default ClusterMarker;
