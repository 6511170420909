import React from "react";
import styled from "styled-components";

const Input = styled(({ className, arr, handleRemove }) => {
  return (
    <div className={className}>
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="#93A3B8"
        >
          <path d="M23 0l-4.5 16.5-6.097-5.43 5.852-6.175-7.844 5.421-5.411-1.316 18-9zm-11 12.501v5.499l2.193-3.323-2.193-2.176zm-8.698 6.825l-1.439-.507 5.701-5.215 1.436.396-5.698 5.326zm3.262 4.287l-1.323-.565 4.439-4.503 1.32.455-4.436 4.613zm-4.083.387l-1.481-.507 8-7.89 1.437.397-7.956 8z" />
        </svg>
      </div>
      <div className="text">
        {arr.length < 1 && (
          <p className="select-input">Selecteer luchthaven(s)</p>
        )}
        {arr.length > 0 && arr.length < 3 && (
          <div className="selected">
            {arr.map((item, index) => (
              <div key={index} className="selected-item">
                {item.label}
              </div>
            ))}
          </div>
        )}
        {arr.length > 2 && (
          <p className="select-input">{arr.length} luchthavens geselecteerd</p>
        )}
      </div>
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#93A3B8"
        >
          <path d="M7 10l5 5 5-5z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    gap: 4px;
    padding: 0 8px;

    .selected-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #fddede;
      border-radius: 4px;
      padding: 4px 6px;
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.88px;
    }
  }
  .select-input {
    margin: 0;
    color: #2e3742;
    margin-left: 0.5rem;
    font-family: Rajdhani, Arial;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.875rem;
    letter-spacing: 0.88px;
    width: 100%;
    white-space: nowrap;
  }
`;

export default Input;
