import styled from "styled-components";
import { setRem } from "../../helpers/cssHelpers";
import { color, font } from "assets/variables";

const Title = styled.span`
  font-size: ${setRem(24)};
  font-family: ${font.family.secondary};
  font-weight: ${font.weight.bold};
  color: ${color.gray.dark};
  line-height: 32px;
`;

export default Title;
