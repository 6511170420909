import { createGlobalStyle } from "styled-components";
import { font, color } from "../assets/variables";

const GlobalStyle = createGlobalStyle`
  *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
   
  body {
    color: ${color.gray.dark};
    font-family: ${font.family.primary};
  }
  
   
    h1{
      font-size: 3rem;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    } 
    
    h2{
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
     
    h3{
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 1rem;
    }
    
    h4{
      font-size: 1.2rem;
      line-height: 1.2;
      margin-bottom: 1.25rem;
      font-weight: ${font.weight.bold};
    }
    
    h5{
      font-size: 1rem;
      margin-bottom: 1.5rem;
      font-weight: bold;
    }
    
    h6{
      font-size: 1rem;
      font-weight: bold;
    }
    
    p{
      line-height: 1.5rem;
      margin: 0 0 1.5rem 0;
    }
`;

export default GlobalStyle;
