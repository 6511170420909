import styled from "styled-components";
import PropTypes from "prop-types";
import { setRem } from "../../helpers/cssHelpers";

const Label = styled.label`
  font-family: "museo_sans500";
  font-size: ${setRem(18)};
  letter-spacing: 1px;
  margin-bottom: 0.1em;
  margin-top: ${setRem(14)};
`;

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
};

export default Label;
